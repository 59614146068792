@import "../../styles/imports";

.root {
  table {
    min-width: 840px;
    thead, tbody {
      th, td {
        user-select: none;
        text-align: left;
        padding: 8px 0;
        &:not(:first-child) {
          padding-left: 21px;
        }
      }
    }

    thead {
      tr {
        th {
          border-bottom: 1px solid $grey;
          font-family: $navigo;
          font-weight: $medium;
          font-size: 13px;
        }
      }
    }

    tbody {
      tr {
        &:hover {
          background-color: $list-item-hover;
        }
      }
    }
  }
}