@import "../../styles/imports";

.root {
  display: flex;
  border-top: 1px solid $grey;
  border-bottom: 1px solid $grey;

  .tab {
    position: relative;
    flex: 1;
    font-family: $navigo;
    font-size: 14px;
    text-align: center;
    margin-top: 24px;
    margin-bottom: 11px;

    &.selected {
      &:after {
        content: "";
        position: absolute;
        bottom: -11px;
        height: 2px;
        left: 0;
        right: 0;
        background-color: $red;
      }
    }

    &:hover {
      cursor: pointer;
      color: $red;
    }

    .title {
      font-weight: $bold;
      margin: 0 12px;
    }

    .subTitle {
      margin-top: 5px;
      font-weight: $normal;
    }
  }
}