@import "../../styles/imports";

.root {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($black, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    max-height: 100%;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}