@import "../../styles/imports";

.root {
  position: relative;
  cursor: pointer;
  .picker {
    position: absolute;
    bottom: 100%;
    left: 0;
    z-index: 500;
  }
  .preview {
    height: 48px;
    width: 120px;
    border: 1px solid $dark-grey;
  }
}