@import "../../styles/imports";

.root {
  button {
    border: none;
    background: none;
    cursor: pointer;
    padding: 16px 24px;
    color: $black;
    font-family: $navigo;
    font-weight: $bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.size-no-padding button {
    padding: 0;
  }

  &.size-small button {
    padding: 8px;
  }

  &.theme-primary button {
    text-transform: uppercase;
  }

  &:not(.disabled) {
    &.theme-primary button {
      background-color: $button;

      &:hover {
        background-color: $button-hover;

        svg {
          fill: $charcoal;
        }

        color: $charcoal;
      }
    }

    &.theme-role button {
      background-color: $clubColor;
      color: $clubContrastColor;

      &:hover {
        svg {
          fill: $clubContrastColorTransparent;
        }

        color: $clubContrastColorTransparent;
      }
    }

    &:not(.theme-primary):not(.theme-role) button {
      &:hover, &:active {
        svg {
          fill: $clubColor;
        }

        color: $clubColor;
      }
    }
  }

  &.disabled {
    pointer-events: none;
    user-select: none;
    background-color: $grey;
    color: $dark-grey
  }
}