@import "../../styles/imports";

.root {
  display: flex;
  align-items: center;

  &:not(:hover) .actions {
    display: none;
  }

  .content {
    flex: 1;
  }

  .actions {
    display: flex;
    align-items: center;
  }
}