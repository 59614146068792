@import "../../styles/imports";

.root {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  z-index: 1000;

  .header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background-color: $clubColor;
    padding: 25px 42px;

    .left {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;

      a {
        font-family: $navigo;
        font-weight: $bold;
        text-transform: uppercase;
        font-size: 22px;
        margin-right: 39px;
        text-decoration: none;
        color: $clubContrastColorTransparent;

        &:hover, &.active {
          color: $clubContrastColor;
        }
      }
    }

    .right {
      display: flex;
      align-items: center;

      .logOutButton {
        white-space: nowrap;
        margin-right: 16px;
      }
    }
  }
}