// font-weight
$light: 300;
$normal: 400;
$normal: 400;
$medium: 500;
$bold: 700;

// Roboto
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap');
$roboto: Roboto, sans-serif;

// Navigo
@import url("https://use.typekit.net/beb2dwn.css");
$navigo: Navigo, sans-serif;