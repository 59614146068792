@import "../../styles/imports";

.root {
  width: 224px;
  position: relative;

  .customRequiredInput {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: calc(100% - 2px);
    background: unset;
    border-color: transparent;
    color: transparent;
    opacity: 0;
    z-index: -1;
    pointer-events: none;
  }

  .iconContainer {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      fill: $dark-grey;
    }
  }

  .input {
    width: calc(100% - (13px * 2));
    height: 20px;
    border: 1px solid $dark-grey;
    padding: 13px;
    font-family: $roboto;
    font-size: 15px;
    font-weight: $normal;

    &::placeholder {
      color: $dark-grey;
    }

    &:focus, &:not(:placeholder-shown) {
      border-color: $black;
    }
  }

  &.hasSearch .input {
    padding-left: 45px;
    width: calc(100% - 13px - 45px)
  }

  &.hasButton .input {
    padding-right: 45px;
    width: calc(100% - 13px - 45px)
  }

  &.hasSearch.hasButton .input {
    width: calc(100% - 45px - 45px)
  }

  .buttonContainer {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    padding: 8px;
    justify-content: center;
  }
}