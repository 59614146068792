@import "../../styles/imports";

.root {
  position: relative;

  &.hasFocus .suggestions {
    display: block;
  }

  .suggestions {
    z-index: 1;
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100%;
    background-color: $white;
    box-shadow: 0 3px 30px #00000029;
    max-height: 250px;
    overflow-y: auto;
  }
}