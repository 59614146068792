@import "../../styles/imports";

.root {
  display: inline-block;
  button {
    padding-left: 0;
  }
  .navLink {
    text-decoration: none;
    text-transform: uppercase;
    .text {
      margin-left: 8px;
      font-family: $navigo;
      font-weight: $bold;
      font-size: 15px;
    }
  }
}