$primaryColor: #C234ED;
$white: #FFFFFF;
$pearl: #FAFAFA;
$grey: #EBEBEB;
$dark-grey: #B2B1B1;
$info: #808080;
$black: #000000;
$charcoal: #464646;
$button: #FFEA00;
$button-hover: #FFEF58;
$list-item-hover: #FFFDE6;
$red: #C71A1A;
$purple: #6A1BB9;
$green: #4CA232;
$orange: #EBA22C;
$blue: #4061ED;
$clubColor: rgba(var(--club-color), 1);
$clubContrastColor: rgba(var(--club-contrast-color), 1);
$clubContrastColorTransparent: rgba(var(--club-contrast-color), 0.5);