@import "../../styles/imports";

.root {
  .header {
    margin: 24px 38px;
    display: flex;
    justify-content: flex-end;
  }

  .body {
    display: flex;
    flex-wrap: wrap;

    &>* {
      margin-bottom: 32px;
      &:not(:last-child) {
        margin-right: 32px;
      }
    }
  }
}