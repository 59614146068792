@import "../../styles/imports";

.root {
  .header {
    margin: 32px 0;

    .name {
      a {
        color: $clubColor;
        &:hover {
          opacity: 0.5;
        }
      }
      color: $clubColor;
      text-transform: uppercase;
      font-family: $navigo;
      font-weight: $bold;
      font-size: 27px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .buttons {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .body {
    display: grid;
    grid-template-columns: calc(50% - (32px / 2)) calc(50% - (32px / 2));
    grid-template-areas: "A B" "C C";
    grid-column-gap: 32px;

    & > :nth-child(1) {
      grid-area: A;
    }
    & > :nth-child(2) {
      grid-area: B;
    }
    & > :nth-child(3) {
      grid-area: C;
    }

    .surface {
      margin-bottom: 21px;

      .title {
        height: calc(72px - (12px * 2));
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 8px 12px 35px;
        font-family: $navigo;
        font-weight: $medium;
        font-size: 15px;

        .withSubTitle {
          display: flex;
          .subTitle {
            margin-left: 16px;
          }
        }

        .buttons {
          display: flex;

          & > *:not(:last-child) {
            margin-right: 8px;
          }
        }
      }

      .attendeesCounter {
        font-family: $navigo;
        font-weight: $bold;
        font-size: 22px;
        padding: 0 16px;
      }

      .coachHoverActionsContainer {
        height: 24px;
      }
    }

    .buttonsContainer {
      white-space: nowrap;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      margin-bottom: 12px;

      &>div {
        margin: 6px;
      }
    }
  }
}