@import "../../styles/imports";

.root {
  height: 100%;
  min-width: 1120px;
  display: flex;
  flex-direction: column;
  background-color: $pearl;
  .header {

  }
  .body {
    flex: 1;
    overflow-y: auto;
    padding-left: 40px;
    padding-right: 46px;
  }
}