@import "../../styles/imports";

.header {
  background-color: $clubColor;
  padding: 0 42px 16px 42px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .filters {
    display: flex;
    align-items: flex-end;
    margin-top: 2px;

    &>*:not(:last-child){
      margin-right: 14px;
    }
  }
}

.root {
  flex: 1;
  display: flex;
  flex-direction: column;

  .body {
    flex: 1;
  }
}