@import "../../styles/imports";

.root {
  min-width: 408px;

  .header {
    padding: 24px 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: $navigo;
    font-size: 15px;
  }
}