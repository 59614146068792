@import "../../styles/imports";

.root {
  background-color: $white;
  border: 1px solid $grey;

  .row {
    &:not(:last-child) {
      border-bottom: 1px solid $grey
    }
  }
}