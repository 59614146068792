@import "../../styles/imports";

.root {
  .percentage {
    font-family: $navigo;
    font-weight: $bold;
    font-size: 22px;
  }
  .checkIcon {
    fill: $green;
  }
}