@import "../../styles/imports";

.root {
  display: flex;
  padding-top: 21px;

  .left {
    margin-right: 16px;
    .logo {
      width: 69px;
      height: 69px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 16px;
    }
  }

  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    .clubNavItem {
      text-transform: uppercase;
      font-family: $navigo;
      font-weight: $bold;
      font-size: 22px;
    }
  }
}