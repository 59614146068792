@import "../../styles/imports";

.root {
  display: inline-flex;
  height: 32px;
  align-items: center;
  color: $pearl;
  border-radius: 16px;
  padding: 0 16px;

  .icon {
    width: 16px;
    height: 16px;
    margin-right: 10px;

    svg {
      fill: $pearl;
    }
  }

  .text {
    font-family: $navigo;
    font-weight: $medium;
    font-size: 12px;
    white-space: nowrap;
    line-height: 100%;
  }

  &.theme-younited {
    background-color: $red;
  }

  &.theme-training {
    background-color: $blue;
  }

  &.theme-teambuilding {
    background-color: $green;
  }

  &.theme-socialActivity {
    background-color: $purple;
  }

  &.theme-friendlyGame {
    background-color: $orange;
  }
}