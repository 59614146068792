@import "./imports";

body {
  margin: 0;
  background-color: #F7F7F7;
  font-family: Roboto, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: navigo, sans-serif;
  font-weight: 700;
  font-style: normal;
}

a {
  text-decoration: none;
}