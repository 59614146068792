@import "../../styles/imports";

.root {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  form {
    min-width: 310px;
    &>* {
      margin-bottom: 8px;
    }
  }

  .title {
    font-family: $navigo;
    font-weight: $medium;
    font-size: 15px;
  }

  .buttonsContainer {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 16px;

    > * {
      margin: 0 !important;
    }
  }

  .logoContainer {
    margin-top: 46px;
  }
}