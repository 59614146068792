@import "../../styles/imports";

.root {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  overflow: hidden;
  pointer-events: none;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 20px;

  .messages {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    pointer-events: all;

    .message {
      color: $white;
      padding: 16px 24px;
      max-width: 350px;
      margin-bottom: 12px;
      cursor: pointer;
      user-select: none;

      &.removed {
        transform: translateX(-100%);
        opacity: 0;
        transition: 300ms;
      }

      &.type-normal {
        background-color: $dark-grey;
      }

      &.type-success {
        background-color: $green;
      }

      &.type-warning {
        background-color: $orange;
      }

      &.type-error {
        background-color: $red;
      }
    }
  }
}