@import "../../styles/imports";

.root {
  &:hover {
    cursor: pointer;
  }
  &.theme-default {
    svg {
      fill: $black;
    }
    &:hover svg {
      fill: $clubColor;
    }
  }
  &.theme-role {
    svg {
      fill: $clubContrastColor;
    }
    &:hover svg {
      fill: $clubContrastColorTransparent
    }
  }
}