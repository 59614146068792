@import "../../styles/imports";

.root {
  display: flex;
  align-items: center;

  .option {
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 8px;
    }

    &.selected {
      fill: $clubColor;
    }
  }
}