@import "../../styles/imports";

.root {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  min-width: 470px;
  background-color: $white;
  box-shadow: 0 3px 30px #00000029;

  &.initialized {
    transform: translateX(0);
    opacity: 1;
    transition: 300ms;
  }

  &:not(.initialized) {
    transform: translateX(100%);
    opacity: 0;
    transition: 300ms;
  }

  &.hidden {
    display: none;
  }

  .container {
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    :global(.header) {
      background-color: $clubColor;
      color: $clubContrastColor;
      display: flex;
      align-items: center;
      font-family: $navigo;
      padding-top: 23px;
      padding-bottom: 28px;

      .backButton {
        display: flex;
        align-items: center;
        line-height: 15px;
        color: $clubContrastColor;
        &:hover {
          color: $clubContrastColorTransparent;
        }
        svg {
          fill: currentColor;
          margin-right: 8px;
        }
      }

      .closeButtonIcon {
        padding: 8px;
      }

      :global(.title),
      :global(.subtitle) {
        flex: 1;
        display: flex;
        margin-left: 12px;
      }

      :global(.title) {
        font-size: 27px;
        font-weight: $normal;
      }

      :global(.subtitle) {
        font-size: 15px;
        color: $clubContrastColorTransparent;
      }
    }

    :global(.body) {
      flex: 1;
      overflow-y: auto;
    }

    :global(.footer) {
      border-top: 1px solid $grey;
      background-color: $pearl;
      padding: 24px;
    }
  }
}