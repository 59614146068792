@import "../../styles/imports";

.root {
  height: calc(100% - (32px * 2));
  max-height: 100%;

  table {
    margin: 32px 0;
    width: 100%;
    border: 1px solid $grey;
    border-collapse: collapse;
    font-size: 15px;

    thead, tbody {
      tr {
        height: 56px;
        text-align: left;

        th, td {
          padding: 0 32px;
        }
      }
    }

    thead {
      tr {
        border-bottom: 1px solid $grey;
        background-color: $white;

        th {
          user-select: none;
          font-family: $navigo;
          font-weight: $medium;
          font-size: 15px;
          padding: 12px 32px;

          &.sortable {
            &:hover {
              cursor: pointer;
              background-color: rgba($button, 0.1);
            }
            .iconContainer {
              display: inline-block;
              float: right;
            }
          }
        }
      }
    }

    tbody {
      tr {
        width: 100%;
        font-family: $roboto;
        font-weight: $light;
        background-color: $white;

        &:nth-child(odd) {
          background-color: #F7F7F7;
        }

        &:global(.clickable) {
          &:hover {
            user-select: none;
            cursor: pointer;
            background-color: $list-item-hover;
          }
        }

        &:global(.selected) {
          outline: 1px solid $button;
          background-color: $list-item-hover;
        }
      }
    }
  }
}