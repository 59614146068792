@import "../../styles/imports";

.root {
  position: relative;
  font-family: $roboto;
  font-weight: $normal;
  user-select: none;

  .value {
    background-color: $white;
    border: 1px solid $white;
    padding: 14px;
  }

  &.hasValue {
    .value {
      border-width: 2px;
      margin: -1px;
      border-color: $black;
    }

    .popup {
      margin-top: 1px;
    }
  }

  &:hover,
  &.expanded {
    .value {
      border-color: $black;
    }
  }

  .popup {
    min-width: 240px;
    position: absolute;
    top: 100%;
    left: 0;
    padding: 16px 16px 0 16px;
    background-color: $white;
    box-shadow: 0 5px 10px #0000001A;

    .option {
      display: flex;
      align-items: center;
      white-space: nowrap;
      margin-bottom: 16px;

      .text {
        margin-left: 12px;
      }
    }

    .buttons {
      margin: 0 -16px;
      padding-left: 16px;
      padding-right: 16px;
      border-top: 1px solid $grey;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}