@import "../../styles/imports";

.titleContainer {
  flex: 1;
}

.roleIconContainer {
  margin-right: 18px;
}

.body {
  margin: 32px 0;

  .info {
    margin: 12px 30px;
    display: flex;
    align-items: center;

    .icon {
      min-width: 16px;
      width: 16px;
      max-width: 16px;
      min-height: 16px;
      height: 16px;
      max-height: 16px;
      margin-right: 26px;

      svg {
        max-width: 16px;
        max-height: 16px;
        fill: $dark-grey;
      }
    }

    .value {
      font-family: $roboto;
      font-weight: $normal;
      font-size: 15px;
    }
  }
}