@import "../../styles/imports";

.root {
  .header {
    margin: 32px 0;

    .clubName {
      text-transform: uppercase;
      font-family: $navigo;
      font-weight: $bold;
      font-size: 27px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .body {
    display: grid;
    grid-template-columns: calc(50% - (32px / 2)) calc(50% - (32px / 2));
    grid-column-gap: 32px;

    .surface {
      margin-bottom: 21px;

      .title {
        height: calc(72px - (12px * 2));
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 8px 12px 35px;
        font-family: $navigo;
        font-weight: $medium;
        font-size: 15px;

        .buttons {
          display: flex;

          & > *:not(:last-child) {
            margin-right: 8px;
          }
        }
      }

      .logo {
        max-width: 200px;
      }

      .logoSizeLimit {
        margin-top: 14px;
        font-family: $roboto;
        font-weight: $normal;
        color: $info;
        font-size: 10px;
      }

      .surfaceBody {
        margin: 24px 35px;
      }

      .clubAdminHoverActionsContainer {
        height: 24px;
      }

      .colorContainer {
        display: flex;
        justify-content: space-between;

        .colorPickers {
          display: flex;

          & > *:not(:first-child) {
            margin-left: 32px;
          }
        }

        .colorExampleContainer {
          font-family: $navigo;
          font-weight: $bold;
          margin-right: 56px;


          .padding {
            padding: 12px 16px;
          }

          .small {
            font-size: 12px;
          }

          .medium {
            font-size: 16px;
          }

          .large {
            font-size: 22px;
          }
        }
      }
    }
  }
}