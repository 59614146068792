@import "../../styles/imports";

.root {
  height: 100vh;
  display: flex;
  flex-direction: column;
  .page {
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
}