@import "../../styles/imports";

.header {
  color: white!important;
  :global(.subtitle) {
    color: rgba(white, 0.7)!important;
  }
}

.titleContainer {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 16px;

  & > :last-child {
    margin-left: 16px;
  }

  .activityTypeIcon {
    margin-right: 18px;
  }

  .progress {
    text-align: center;

    .percentage {
      font-family: $navigo;
      font-weight: $bold;
      font-size: 22px;
      color: white;
    }

    .text {
      font-family: $roboto;
      font-weight: $normal;
      font-size: 10px;
      color: rgba(white, 0.7);
    }
  }
}

.body {
  display: flex;
  flex-direction: column;
  height: 100%;

  .section {
    padding: 16px 14px 19px 14px;
    background-color: $pearl;

    .buttonContainer {
      display: flex;
      justify-content: flex-end;
    }

    .infoContainer {
      display: flex;
      justify-content: space-around;
      margin-top: 8px;

      .info {
        text-align: center;

        .text {
          font-family: $navigo;
          font-weight: $light;
          font-size: 13px;
          margin-bottom: 8px;
        }

        .value {
          font-family: $navigo;
          font-weight: $bold;
          font-size: 22px;
        }
      }
    }
  }

  .navListContainer {
    flex: 1;
    background-color: $white;
    overflow-y: auto;

    .attendingTeam {
      display: flex;
      align-items: center;

      .icon {
        margin-right: 24px;

        svg.green {
          fill: $green;
        }

        svg.red {
          fill: $red;
        }
      }

      .name {
        flex: 1;
      }

      .count {
      }
    }

    .attendee {
      display: flex;
      align-items: center;

      .icon {
        margin-right: 24px;
        svg {
          fill: $dark-grey;
        }
      }

      .name {
        flex: 1;
      }
    }
  }

  .commentContainer {
    display: flex;
    padding: 24px;
    .icon {
      fill: $dark-grey;
      margin-right: 16px;
    }
    .comment {
      flex: 1;
      font-family: $roboto;
      font-weight: $light;
      font-size: 15px;
      max-height: 100px;
      max-width: 360px;
      overflow-y: auto;
    }
  }
}