@import "../../styles/imports";

.checkbox {
  display: flex;
  align-items: center;
  margin-top: 16px;

  .checkboxText {
    margin-left: 8px;
  }
}
