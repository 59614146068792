@import "../../styles/imports";

.root {
  display: flex;
  align-items: center;

  &.align-left {
    justify-content: flex-start;
  }

  &.align-center {
    justify-content: center;
  }

  &.align-right {
    justify-content: flex-end;
  }

  &.padded {
    padding: 16px;
  }

  &>*:not(:last-child) {
    margin-right: 12px;
  }
}