@import "../../styles/imports";

.root {
  position: relative;

  input {
    display: none;
  }

  width: 24px;
  height: 24px;
  border-radius: 6px;
  border: 1px solid $dark-grey;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $clubContrastColor;

  svg {
    fill: currentColor;
  }

  &:not(.checked) svg {
    display: none;
  }

  &.checked {
    background-color: $clubColor;
  }

  &:not(.disabled) {
    &:hover {
      border-color: $black;
    }
  }

  &.disabled {
    border-color: $grey !important;
    pointer-events: none;
    background-color: $dark-grey;
  }
}


label:hover > .root {
  border-color: $black;
}