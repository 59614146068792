@import "../../styles/imports";

.selectListContainer {
  height: 400px;
  max-height: 400px;
  overflow-y: scroll;
}

.invitedTeamsCount {
  flex: 1;
  font-family: $navigo;
  font-weight: $medium;
  font-size: 15px;
}