@import "../../styles/imports";

.root {
  display: block;
  .label {
    font-family: $navigo;
    font-size: 13px;
    font-weight: $normal;
    margin-bottom: 8px;
  }
}