@import "../../styles/imports";

.print-portal {
  display: none;
}

.header {
  background-color: $clubColor;
  padding: 0 42px 16px 42px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .filters {
    display: flex;
    align-items: flex-end;
    margin-top: 2px;

    & > *:not(:last-child) {
      margin-right: 14px;
    }
  }
}

.root {
  flex: 1;
  display: flex;
  flex-direction: column;

  .body {
    margin: 84px 0 24px;
    flex: 1;

    .flex {
      width: 100%;
      display: flex;
      align-items: stretch;

      & > :not(:last-child) {
        margin-right: 32px;
      }
    }

    .grid {
      display: grid;
      grid-column-gap: 32px;
      grid-row-gap: 21px;
    }

    .flex1 {
      flex: 1;
    }

    .cols1,
    .cols2,
    .cols3,
    .cols4 {
      display: grid;
      grid-column-gap: 32px;
    }

    .cols2 {
      grid-template-columns: repeat(2, calc(50% - (32px / 2)));
    }

    .cols3 {
      grid-template-columns: repeat(3, calc(33% - (32px / 3)));
    }

    .cols4 {
      grid-template-columns: repeat(4, calc(25% - 24px));
    }

    .surface {

      .title {
        height: calc(72px - (12px * 2));
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 8px 12px 35px;
        font-family: $navigo;
        font-weight: $medium;
        font-size: 15px;
        margin-right: 32px;

        .withSubTitle {
          display: flex;

          .subTitle {
            text-decoration: underline;
            margin-left: 16px;
          }
        }

        .buttons {
          display: flex;

          & > *:not(:last-child) {
            margin-right: 8px;
          }
        }
      }
    }

    .number {
      font-family: $navigo;
      font-weight: $normal;
      font-size: 27px;
    }

    .table {
      min-width: 250px;
      min-height: 300px;
      font-family: $navigo;
      font-size: 14px;
      .row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 22px;
      }
    }

    .chartContainer {
      position: relative;

      .chart {
        position: relative;
        margin: 0 auto;

        &.visibleOverflow svg {
          overflow: visible;
        }
      }

      .legend {
        position: absolute;
        z-index: 1;
        background-color: rgba($grey, 0.3);
        span {
          font-size: 12px;
          margin-left: 8px;
        }
        svg {
          stroke-width: 18px;
          height: 10px;
        }
      }

      .hint {
        background-color: white;
        font-family: $navigo;
        font-weight: $normal;
        font-size: 12px;
        padding: 3px 8px;
        user-select: none;
        pointer-events: none;
      }
    }
  }
}