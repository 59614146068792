@import "../../styles/imports";

.root {
  .item {
    color: $black;
    display: block;
    text-decoration: none;
    font-family: $roboto;
    font-weight: $normal;
    font-size: 15px;
    padding: 12px 32px;
    user-select: none;

    &:hover {
      background-color: $list-item-hover;
      &:not(.noClick) {
        cursor: pointer;
      }
    }
  }
}